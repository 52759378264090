import React, { useContext, useLayoutEffect } from "react";
import { useInView } from "react-intersection-observer";
import { cn, imageBuilder } from "../../lib/helpers";
import Container from "../global/container";
import { GraphicBackground } from "../global/graphic-background";
import { SiteContext } from "../global/site-context";
import PortableText from "../portableText";
import { SectionContext } from "./context";
import { GRADIENT_BACKGROUND_STYLE } from "./snowflake/hero";
import { Image } from "./featured-media";
import { pageDocPath } from "../../../helpers";
import { Logo } from "../icon/logo";

import * as styles from "../global/global.module.css";
import * as portableTextStyles from "../portableText.module.css";

const GRADIENT_BG_ONLY_STYLE =
  "linear-gradient(87deg, rgba(238, 226, 252, 0.20) 5.36%, rgba(235, 233, 117, 0.20) 95.85%), linear-gradient(0deg, rgba(255, 255, 255, 0.70) 0%, rgba(255, 255, 255, 0.70) 100%), #D3B7FF";

const GRADIENT_BOX_STYLE =
  "radial-gradient(56.12% 73% at 37.75% -3.18%, rgba(107, 255, 228, 0.25) 0%, rgba(107, 255, 228, 0.00) 100%), radial-gradient(58.3% 72.52% at 69.25% 58.49%, rgba(207, 148, 59, 0.45) 0%, rgba(255, 143, 107, 0.00) 100%), linear-gradient(80deg, rgba(211, 183, 255, 0.15) 1.04%, rgba(235, 233, 117, 0.20) 61.76%), linear-gradient(0deg, rgba(255, 255, 255, 0.70) 0%, rgba(255, 255, 255, 0.70) 100%), #D3B7FF";

const GRADIENT_DARK_STYLE =
  "radial-gradient(56.12% 73% at 37.75% -3.18%, rgba(58, 255, 219, 0.55) 0%, rgba(107, 255, 228, 0.00) 100%), radial-gradient(69.76% 154.92% at 71.17% 66.1%, rgba(255, 173, 49, 0.40) 0%, rgba(255, 99, 49, 0.00) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), linear-gradient(79deg, rgba(211, 183, 255, 0.13) -5.27%, rgba(255, 252, 45, 0.20) 82.95%), linear-gradient(0deg, rgba(54, 15, 166, 0.70) 0%, rgba(54, 15, 166, 0.70) 100%), #D3B7FF";

const gradientMap = new Map([
  ["gradient", GRADIENT_BACKGROUND_STYLE],
  ["gradientOnly", GRADIENT_BG_ONLY_STYLE],
  ["gradientBox", GRADIENT_BOX_STYLE],
  ["gradientDark", GRADIENT_DARK_STYLE],
  ["gradientLightBox", GRADIENT_BG_ONLY_STYLE],
  [
    "gradientSpotlights",
    "url(/static/img/background-spotlights.svg) no-repeat center center/cover",
  ],
  [
    "gradientSpotlightsSpiral",
    "url(/static/img/spotlights-spiral.svg) no-repeat center center/cover",
  ],
]);

const GRADIENT_BOXES = [
  "gradientBox",
  "gradientLightBox",
  "gradientSpotlights",
  "gradientSpotlightsSpiral",
];

// @todo remove subheader field, we've switched to _rawSummary

export const CONTAINER_MAP = new Map([
  ["narrow", "max-w-screen-sm"],
  ["thin", "max-w-screen-md"],
  ["large", "max-w-screen-lg"],
  ["full", "max-w-screen-boundary"],
]);

const PADDING_MAP = new Map([
  ["default", "py-16 md:py-24"],
  ["none", ""],
  ["tight", "py-8"],
  ["top", "pt-16 md:pt-24"],
  ["bottom", "pb-16 md:pb-24"],
  ["bottomExtra", "pt-16 md:pt-24 pb-32 md:pb-44"],
  ["topOverlap", "pb-16 md:pb-24 -mt-20 md:-mt-28"],
  // one-off
  ["topOverlapLarge", "pb-16 md:pb-24 -mt-32 md:-mt-44"],
  ["bottomExtraLarge", "pt-16 md:pt-24 pb-36 md:pb-52"],
  ["topOverlapNone", "-mt-12 md:-mt-16"],
]);

const COLOR_MAP = new Map([
  ["blackGlow", "bg-gray-900"],
  ["yellow", "bg-yellow-200"],
  ["gray", "bg-gray-200"],
  ["blue", "bg-blue-100"],
  ["white", "bg-white"],
  ["lavenderUltraLight", "bg-purple-ultralight"],
  ["dots", "bg-dark-5"],
  ["dots-lavender", "bg-lavender"],
  ["dots-peach", "bg-peach"],
  ["dots-mint", "bg-mint"],
  ["dots-robin", "bg-robin"],
  ["dots-lemon", "bg-lemon"],
  ["gradientLavender", styles.gradientLavender],
]);

// note: props is gets forwarded
export const SectionWrapper = (props) => {
  const {
    anchor,
    children,
    background,
    padding,
    container,
    name,
    layout,
    noHeaders,
    desktopOnly,
    isColumn,
    effect,
    // homepage handling
    setActive,
    index,
    backgroundCentered,
  } = props;
  const site = useContext(SiteContext);
  const [ref, inView] = useInView({
    // rootMargin: "-100px 0",
    threshold: [0.2, 0.95],
    initialInView: true,
  });

  const mainImage = props.image || props.mainImage;

  const bg = background
    ? COLOR_MAP.has(background)
      ? COLOR_MAP.get(background)
      : background
    : "";

  // @todo create page-level setting as default for all sections
  // @todo Handle one-off better!!!
  const extraSpace =
    site.isHomepage ||
    ["/product/customer-intelligence-platform/"].includes(
      pageDocPath(site?.doc)
    );
  const paddingClass =
    extraSpace && anchor === "overlap"
      ? PADDING_MAP.get("topOverlapLarge")
      : extraSpace && name === "header"
      ? PADDING_MAP.get("bottomExtraLarge")
      : PADDING_MAP.has(padding)
      ? PADDING_MAP.get(padding)
      : PADDING_MAP.get("default");

  const fullHeight = padding === "full";

  const topAlign = ["homepageTry"].includes(name);

  const heightClass = topAlign
    ? "min-h-screen grid place-items-start"
    : fullHeight
    ? "min-h-screen grid place-items-center"
    : "";

  // @todo avoid these being set for each on page load
  useLayoutEffect(() => {
    if (site.isHomepage && !setActive) return;

    if (inView && anchor) {
      site.setActiveSection(anchor);
    }
  }, [inView]);

  // @todo homepage hack to prevent video fade artifacts
  // const peekabooSections = ["intro", "logos", "customers", "more-logos"];
  // const peekabooStyle = peekabooSections.includes(anchor) ? { zIndex: 99 } : {};

  const isHeader = name === "header";

  // @todo background setting on all sections
  const bgStyle =
    isHeader &&
    [
      "dots",
      "dots-lavender",
      "dots-peach",
      "lime",
      "mint",
      "dots-mint",
      "dots-robin",
      "dots-lemon",
    ].includes(background)
      ? {
          backgroundImage: `url('/static/img/tile.svg')`,
          backgroundSize: "12px 12px",
          // backgroundBlendMode: "lighten",
        }
      : false;

  const sectionIsDarkBg =
    (site.isDarkBg && !bg) ||
    ["black", "gray-900", "blackGlow"].includes(background);
  const isGlow = ["blackGlow", "whiteGlow"].includes(background);
  const isGradient = ["gradient", "gradientOnly", "gradientDark"].includes(
    background
  );
  const isGradientBox = GRADIENT_BOXES.includes(background);
  const isSvg = mainImage?.asset?.extension === "svg";
  const isBgOverlap = mainImage?.layout === "overlap";

  return (
    <SectionContext.Provider
      value={{
        ...props,
        isDarkBg: sectionIsDarkBg,
        container,
        isColumn,
      }}
    >
      <div
        id={anchor}
        className={cn(
          "relative",
          paddingClass,
          heightClass,
          desktopOnly ? "hidden sm:block" : null,
          background === "black" ? "bg-gray-900" : background ? bg : "",
          sectionIsDarkBg ? "text-white" : "",
          ["gradientOnly", "gradientDark"].includes(background) &&
            !["mediaRight", "centered"].includes(layout)
            ? "md:pb-36"
            : null
        )}
        style={
          isGradient && gradientMap.has(background)
            ? { background: gradientMap.get(background) }
            : null
        }
        ref={ref}
      >
        {bgStyle ? (
          <>
            <div
              className="absolute top-0 right-0 bottom-0 left-0"
              style={{ ...bgStyle }}
            ></div>
            {layout === "mediaRight" ? null : mainImage ? (
              <Container maxWidth={CONTAINER_MAP.get(container) || null}>
                <div
                  className={cn(
                    "hidden md:block absolute top-0 right-0 bottom-0 left-0 bg-no-repeat",
                    layout === "mediaTop"
                      ? "bg-top"
                      : backgroundCentered || layout === "mediaCentered"
                      ? "bg-center mt-12"
                      : ["narrow", "thin", "large"].includes(container)
                      ? "bg-left mt-12"
                      : layout === "mediaSmall"
                      ? "bg-center bg-contain mt-24 mb-12"
                      : "bg-left-bottom xl:bg-bottom",

                    effect !== "none" ? styles.pulseGrow : null
                  )}
                  style={{
                    backgroundImage: !isSvg
                      ? `url("${imageBuilder(mainImage)
                          .width(
                            Math.round(
                              mainImage.asset.metadata.dimensions.width
                            )
                          )
                          .height(
                            Math.round(
                              mainImage.asset.metadata.dimensions.height
                            )
                          )
                          .quality(100)
                          .auto("format")
                          .url()}")`
                      : `url("${imageBuilder(mainImage)
                          .quality(100)
                          .auto("format")
                          .url()}")`,
                    marginLeft: "50%",
                    backgroundSize: !isSvg
                      ? `${mainImage.asset.metadata.dimensions.width / 2}px`
                      : null,
                  }}
                ></div>
              </Container>
            ) : (
              <GraphicBackground />
            )}
          </>
        ) : isGlow ? (
          <>
            <div
              className={cn(
                "absolute -top-80 left-0 bottom-0 w-2/3 bg-gradient-radial from-spotlight-robin via-transparent to-transparent",
                background === "whiteGlow" ? "opacity-30" : "opacity-20"
              )}
            ></div>
            <div
              className={cn(
                "absolute top-0 bottom-0 right-0 w-2/3 bg-gradient-radial from-spotlight-salmon via-transparent to-transparent",
                background === "whiteGlow" ? "opacity-30" : "opacity-20"
              )}
            ></div>
          </>
        ) : isGradient ? (
          <div className="relative">
            {["gradientDark"].includes(background) && (
              <div
                className={cn(
                  "absolute left-0 top-24",
                  background === "gradient" ? "opacity-80" : "opacity-30"
                )}
              >
                <Image
                  src="/static/img/dot-circle.svg"
                  alt="background dots"
                  width={800}
                />
              </div>
            )}
            {layout === "mediaRight" ? null : (
              <div
                className={cn(
                  "relative mx-auto hidden",
                  isBgOverlap
                    ? "max-w-screen-max xl:block"
                    : cn("md:block", CONTAINER_MAP.get(container))
                )}
              >
                <Image
                  image={mainImage}
                  width={
                    isBgOverlap
                      ? mainImage.asset.metadata.dimensions.width
                      : 500
                  }
                  className={cn(
                    "absolute",
                    isBgOverlap ? "-top-44 left-0 w-full" : "top-0 right-0"
                  )}
                />
              </div>
            )}
          </div>
        ) : null}
        <Container
          className={cn(
            "relative",
            background === "gradientDark" ? "text-white" : null,
            isGradientBox
              ? "overflow-hidden p-12 lg:px-12 xl:px-12 max:px-12 lg:rounded-xl lg:border lg:border-dark-10"
              : null,
            background === "gradientLightBox"
              ? "p-6 lg:px-6 xl:px-6 max:px-6 rounded-xl border border-dark-10"
              : null
          )}
          maxWidth={
            // @todo go update widths on pages in CMS
            name === "resourcesSuggestions" &&
            ["guide", "video"].includes(site.doc.contentType)
              ? CONTAINER_MAP.get("large")
              : // use page-width for full-width section header, should be used wider
              container === "full" && name === "signalSources"
              ? CONTAINER_MAP.get(site?.doc.pageWidth)
              : CONTAINER_MAP.get(container) || null
          }
          style={
            isGradientBox ? { background: gradientMap.get(background) } : null
          }
        >
          {background === "gradientBox" && (
            <Logo
              iconOnly={true}
              className="w-64 absolute -top-20 -left-12 mix-blend-overlay"
            />
          )}
          {!noHeaders && <SectionHeaders {...props} />}
          {children}
        </Container>
      </div>
    </SectionContext.Provider>
  );
};

export const SectionHeader = ({ children }) => (
  <h3 className="text-lg uppercase font-black mb-4">{children}</h3>
);

export const SectionHeaders = ({
  layout,
  header,
  _rawSummary,
  padding,
  _type,
  name,
  isTightHeaders,
  isColumn,
}) => {
  const site = useContext(SiteContext);

  const layoutMap = new Map();
  layoutMap.set("centered", "text-center mx-auto md:w-3/4");
  layoutMap.set("constrainedLeft", "mr-auto md:w-2/3");

  const tightenHeader =
    isTightHeaders ||
    padding === "tight" ||
    ["subscribe", "comment", "tags"].includes(name);

  return (
    <>
      {(header || _rawSummary) && (
        <div
          className={cn(
            // title.length + toPlainText(section._rawSummary).length > 200
            // ? "max-w-screen-md"
            // : "max-w-screen-sm",
            site.isHomepage && layout !== "centered" ? "max-w-xl" : null,
            layoutMap.has(layout) ? layoutMap.get(layout) : "",
            padding === "none" ? "" : tightenHeader ? "pb-4" : "pb-10"
          )}
        >
          {header && (
            <h3
              className={cn(
                header.length > 48 ? "w-5/6 mx-auto" : null,

                "text-xl-homepage font-semibold leading-none sm:leading-tight tracking-tight",

                _type === "webForm" ? layoutMap.get("centered") : null
              )}
            >
              {header}
            </h3>
          )}
          {_rawSummary && (
            <div className="pt-4">
              <PortableText
                blocks={_rawSummary}
                className={isColumn ? portableTextStyles.checkList : null}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};
