import React from "react";
import { cn } from "../lib/helpers";
import { ImageBuilder } from "./global/image-builder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { AddToCalBlock } from "./sections/snowflake/add-to-cal";
import { Button } from "./button";

const SIGNAL_SOURCES_LIST = [
  {
    header: "1st-party",
    list: [
      {
        company: "Airmeet",
        anchor: "airmeet",
        url: "https://www.airmeet.com/",
      },
      { company: "Aligned", anchor: "aligned", url: "https://alignedup.com/" },
      {
        company: "Census",
        anchor: "census",
        url: "https://www.getcensus.com/",
      },
      {
        company: "Hightouch",
        anchor: "hightouch",
        url: "https://hightouch.com/",
      },
      {
        company: "Koala",
        anchor: "koala",
        url: "https://getkoala.com/",
      },
      {
        company: "Toplyne",
        anchor: "toplyne",
        url: "https://www.toplyne.io/",
      },
      {
        company: "Warmly",
        anchor: "warmly",
        url: "https://warmly.ai/",
      },
    ],
  },
  {
    header: "2nd-party",
    list: [
      {
        company: "Bevy",
        anchor: "bevy",
        url: "https://www.bevy.com/",
      },
      {
        company: "Bitergia",
        anchor: "bitergia",
        url: "https://bitergia.com/",
      },
      {
        company: "DEV",
        anchor: "dev",
        url: "https://dev.to/",
      },
      {
        company: "Discourse",
        anchor: "discourse",
        url: "https://www.discourse.org/",
      },
      {
        company: "Gradual",
        anchor: "gradual",
        url: "https://www.gradual.com/",
      },
      {
        company: "Scarf",
        anchor: "scarf",
        url: "https://about.scarf.sh/",
      },
    ],
  },
  {
    header: "3rd-party",
    list: [
      {
        company: "Clay",
        anchor: "clay",
        url: "https://www.clay.com/",
      },
      {
        company: "Keyplay",
        anchor: "keyplay",
        url: "https://keyplay.io/",
      },
      {
        company: "RB2B",
        anchor: "rb2b",
        url: "https://www.rb2b.com/",
      },
      {
        company: "Warmly",
        anchor: "warmly",
        url: "https://warmly.ai/",
      },
    ],
  },
];

const LOGO_TABLE = [
  {
    company: "Apollo",
    category: "Outbound sequencing",
    url: "https://www.apollo.io/",
    logo: "apolloio-logo.png",
    anchor: "apollo",
  },
  {
    company: "Groove by Clari",
    category: "Outbound sequencing",
    url: "https://www.clari.com/",
    logo: "clari-logo.png",
    anchor: "clari",
  },
  {
    company: "HubSpot",
    category: "Outbound sequencing",
    url: "https://www.hubspot.com/",
    logo: "hubspot-logo.png",
    anchor: "hubspot",
  },
  {
    company: "Mixmax",
    category: "Outbound sequencing",
    url: "https://www.mixmax.com/",
    logo: "mixmax-logo.png",
    anchor: "mixmax",
  },
  {
    company: "Outreach",
    category: "Outbound sequencing",
    url: "https://www.outreach.io/",
    logo: "outreach-logo.png",
    anchor: "outreach",
  },
  {
    company: "Salesloft",
    category: "Outbound sequencing",
    url: "https://www.salesloft.com/",
    logo: "salesloft-on-light.png",
    anchor: "salesloft",
  },
  {
    company: "Nooks",
    category: "Dialing",
    url: "https://www.nooks.ai/",
    logo: "nooks-logo.png",
    anchor: "nooks",
  },
  {
    company: "Dux-soup",
    category: "LinkedIn engagement",
    url: "https://www.dux-soup.com/",
    logo: "dux-soup-logo.png",
    anchor: "dux-soup",
  },
  {
    company: "HeyReach",
    category: "LinkedIn engagement",
    url: "https://www.heyreach.io/",
    logo: "heyreach.png",
    anchor: "heyreach",
  },
  {
    company: "La Growth Machine",
    category: "LinkedIn engagement",
    url: "https://lagrowthmachine.com/",
    logo: "lagrowthmachine-logo.png",
    anchor: "lagrowthmachine",
  },
  {
    company: "Sendspark",
    category: "Video personalization",
    url: "https://www.sendspark.com/",
    logo: "sendspark.png",
    anchor: "sendspark",
  },
  {
    company: "Crossbeam / Reveal",
    category: "Ecosystem-led growth",
    url: "https://www.getcrossbeam.com/",
    logo: "crossbeam.png",
    anchor: "crossbeam",
  },
  {
    company: "Swarm",
    category: "Ecosystem-led growth",
    url: "https://www.theswarm.com/",
    logo: "theswarm-logo.png",
    anchor: "swarm",
  },
];

export const CustomBlocks = ({ node }) => {
  switch (node.customBlock) {
    case "blogList":
      return (
        <div className="leading-tight flex w-full mb-6">
          {SIGNAL_SOURCES_LIST.map((n, i) => (
            <div
              key={n.header}
              className={cn(
                "flex-grow",
                i > 0 ? "border-l border-dark-10" : null
              )}
            >
              <div className="text-sm md:text-base font-semibold bg-purple-default p-4 block">
                {n.header}
              </div>
              {n.list.map((link, i) => (
                <div className="p-2" key={link.company}>
                  {
                    <a href={`#${link.anchor}`} className="flex justify-center">
                      <ImageBuilder
                        src={`/static/img/logos/signals/${link.anchor}-signal-partner.png`}
                        height={28}
                        className="mr-1 transform transition-card scale-100 hover:scale-105"
                        style={{ maxHeight: "80px" }}
                        alt={`${link.company} logo`}
                      />
                    </a>
                  }
                </div>
              ))}
            </div>
          ))}
        </div>
      );

    case "blogLogoTable":
      return (
        <div className="my-8 grid grid-cols-2">
          {LOGO_TABLE.map((c) => (
            <>
              <div className="border-r border-dark-10 px-2 py-4 block">
                <a
                  href={`#${c.anchor}`}
                  className="font-semibold text-link hover:underline flex justify-center"
                  title={c.company}
                >
                  <ImageBuilder
                    src={`/static/img/logos/partners/${c.logo}`}
                    height={48}
                    className="transform transition-card scale-100 hover:scale-105"
                    style={{ maxHeight: "35px" }}
                    alt={`${c.company} logo`}
                  />
                </a>
              </div>
              <div className="p-4 flex items-center">
                <div className="mr-4">
                  <a
                    href={c.url}
                    target="_blank"
                    className="text-link hover:text-black"
                  >
                    <FontAwesomeIcon
                      icon={faExternalLink}
                      className="mb-0.5 transform transition-card scale-100 hover:scale-110"
                    />
                  </a>
                </div>
                <span className="leading-tight text-sm px-2 py-1 bg-dark-5 mr-1 mb-1 whitespace-nowrap rounded-md">
                  {c.category}
                </span>
              </div>
            </>
          ))}
        </div>
      );

    case "addToCal":
      return (
        <div className="flex justify-center items-start gap-4">
          <Button
            text="Join Meeting Now"
            href="https://us02web.zoom.us/j/86193173488?pwd=K25wdTVVUXFMMSttZ2lURGgyVjNyZz09"
            attributes={{ target: "_blank" }}
          />
          <AddToCalBlock />
        </div>
      );
  }
};
