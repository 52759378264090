import React, { useContext, useState } from "react";
import { cn, toPlainText } from "../lib/helpers";
import { InfoIcon } from "./sections/snowflake/pricing-plans";
import PortableText from "./portableText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTowerCell,
  faCircleQuestion,
  faSquarePen,
  faLightbulb,
  faWarning,
  faCircleInfo,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { SiteContext } from "./global/site-context";

import ReactTooltip from "react-tooltip";

import * as styles from "./global/global.module.css";
import * as pricingStyles from "./sections/snowflake/pricing-plans.module.css";

const ICON_SIZE = "lg";

const CALLOUT_STYLE_MAP = new Map([
  [
    "warning",
    {
      color: "orange-800",
      bgColor: "rgba(243, 122, 9, 0.05)",
      borderColor: "rgba(243, 122, 9, 0.3)",
      icon: <FontAwesomeIcon icon={faTowerCell} size={ICON_SIZE} />,
    },
  ],
  [
    "danger",
    {
      color: "red",
      bgColor: "rgba(236, 75, 41, 0.05)",
      borderColor: "rgba(236, 75, 41, 0.3)",
      icon: <FontAwesomeIcon icon={faWarning} size={ICON_SIZE} />,
    },
  ],
  [
    "success",
    {
      color: "green-100",
      bgColor: "rgba(22, 163, 74, 0.05)",
      borderColor: "rgba(22, 163, 74, 0.3)",
      icon: <FontAwesomeIcon icon={faLightbulb} size={ICON_SIZE} />,
    },
  ],
  [
    "info",
    {
      color: "highlight-default",
      bgColor: "rgba(236, 239, 253)",
      borderColor: "rgba(63, 100, 239, 0.3)",
      icon: <FontAwesomeIcon icon={faCircleInfo} size={ICON_SIZE} />,
    },
  ],
  [
    "question",
    {
      color: "limeDark",
      bgStyle: styles.gradientEmerald,
      borderColor: "rgba(25, 170, 8, 0.3)",
      icon: <FontAwesomeIcon icon={faCircleQuestion} size={ICON_SIZE} />,
    },
  ],
  [
    "template",
    {
      color: "link",
      bgStyle: styles.gradientLavender,
      borderColor: "rgba(114, 62, 199, 0.3)",
      icon: <FontAwesomeIcon icon={faSquarePen} size={ICON_SIZE} />,
    },
  ],
]);

export const Callout = ({ node, children }) => {
  const site = useContext(SiteContext);
  const [toggled, setToggle] = useState();

  const isPrompt = site?.doc._type === "prompt";
  const isExpandable =
    isPrompt &&
    ["template"].includes(node?.style) &&
    node?.header.match("Prompt");

  const style = CALLOUT_STYLE_MAP.get(
    node?.template ? "template" : node?.style || "info"
  );
  const isInline = node?._type === "inlineInfoBox";
  const header = node?.header;
  const body = node?.body;

  return node ? (
    <span
      className={cn(
        "rounded-lg mb-4 items-start relative",
        !header ? "flex" : "block",
        style?.bgStyle || "bg-dark-5",
        // !node?.header ? "border-l-4" : "border",
        // `border-${style?.color || "dark-20"}`,
        isInline ? "mt-4" : null,
        isExpandable ? "pb-10" : null,
        isPrompt ? "pb-4" : "py-4"
      )}
      style={{
        background: !style?.bgStyle ? style.bgColor : null,
        boxShadow: `0px 0px 0px 1px ${style.borderColor || "rgba(0,0,0,0.3)"}`,
      }}
    >
      {header ? (
        <span
          className={cn(
            "relative flex items-center px-4 font-bold",
            body ? "pb-4 mb-4 border-b" : null,
            `text-${style?.color || "secondary"}`,
            isPrompt ? "pt-4" : null
          )}
          style={{ borderColor: style.borderColor || "rgba(0,0,0,0.3)" }}
        >
          <span className={cn("inline-block mr-3")}>{style?.icon}</span>
          {header}
          {isPrompt && (
            <>
              <button
                className="absolute top-0 right-0 bottom-0 left-0 cursor-pointer hover:bg-dark-30 pt-3 focus:outline-none mix-blend-overlay"
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(toPlainText(body));
                  site.metrics.logClick("copy-click", "prompt");
                }}
                data-tip="Click to copy"
                data-for={node._key}
              ></button>
              <ReactTooltip
                textColor="#fff"
                backgroundColor="#333333BB"
                effect="solid"
                className={pricingStyles.toolTipSmall}
                offset={{ top: -2 }}
                id={node._key}
              />
            </>
          )}
        </span>
      ) : (
        <div
          className={cn(
            "ml-4 mt-1",
            `text-${style?.color ? style.color : "secondary"}`,
            isPrompt ? "pt-4" : null
          )}
        >
          {style?.icon || <InfoIcon />}
        </div>
      )}
      <PortableText
        blocks={body}
        className={cn(
          "px-4",
          !header ? `py-1 leading-normal text-${style?.color}` : null,
          isExpandable ? "overflow-hidden" : null,
          isPrompt
            ? ["template"].includes(node?.style)
              ? "font-mono text-sm"
              : "pt-4"
            : null,
          styles.calloutLinks
        )}
        style={{
          maxHeight: isExpandable && !toggled ? "12rem" : "99rem",
          transition: "max-height .3s",
        }}
      />
      {isExpandable && (
        <a
          className={cn(
            "absolute left-0 bottom-0 right-0 text-center rounded-b-lg bg-dark-5 py-2 hover:bg-dark-10 hover:text-black cursor-pointer",
            `text-${style?.color || "secondary"}`
          )}
          onClick={() => {
            setToggle(!toggled);
            site.metrics.logClick("toggle-click", "prompt");
          }}
          role="button"
        >
          <FontAwesomeIcon
            icon={faArrowDown}
            className="h-5"
            size="lg"
            transform={toggled ? "rotate-180" : null}
          />
        </a>
      )}
    </span>
  ) : null;
};
