import React, { useContext } from "react";
import { mapEdgesToNodes } from "../../../lib/helpers";
import { graphql, useStaticQuery } from "gatsby";
import { pageDocPath } from "../../../../helpers";
import { ImageBuilder } from "../../global/image-builder";
import { ArrowRight } from "../../icon/arrow-left";
import ReactTooltip from "react-tooltip";

import * as styles from "../snowflake/pricing-plans.module.css";
import { SiteContext } from "../../global/site-context";

const LOGO_IMAGE_SIZE = 40;

const namePrefixMap = new Map([
  ["Common Room API", "API"],
  ["Google Sheets import", "Google Sheets"],
]);

const integrationsListQuery = graphql`
  query IntegrationsListSectionQuery {
    docs: allSanityIntegration(
      sort: { fields: [orderRank], order: ASC }
      filter: {
        isPublished: { eq: true }
        mainImageSolid: { asset: { _id: { ne: null } } }
      }
    ) {
      edges {
        node {
          _type
          _id
          company
          mainImageSolid {
            ...SanityImageSimple
          }
          status
          categories {
            _id
            slug {
              current
            }
          }
          docRef {
            _id
            _type
            slug {
              current
            }
            menu
            menuParent
          }
        }
      }
    }
  }
`;

const MARKETING_ID = "0ca9a6b3-3e40-42ed-8e2b-3f798a365fb4";

export const IntegrationsList = ({ section }) => {
  const site = useContext(SiteContext);
  const { docs } = useStaticQuery(integrationsListQuery) || {};
  const integrations = section.category
    ? mapEdgesToNodes(docs).filter((d) =>
        d.categories.find((c) => c.slug.current === section.category)
      )
    : mapEdgesToNodes(docs);

  return (
    <>
      <ul className="flex flex-wrap gap-3 max:gap-4 mb-8">
        {integrations.map((doc) => (
          <li key={doc._id}>
            <a
              href={
                site.isActive("linkIntegrations") &&
                doc.status === "published" &&
                doc.docRef
                  ? pageDocPath(doc.docRef)
                  : null
              }
              className="flex items-center transform transition-size scale-100 hover:scale-110"
              data-tip={doc.company}
              data-for={doc._id}
            >
              <ImageBuilder
                className="rounded-lg w-14"
                image={doc.mainImageSolid}
                height={LOGO_IMAGE_SIZE}
                width={LOGO_IMAGE_SIZE}
                quality={100}
                alt={`${doc.company} logo`}
              />
            </a>
            <ReactTooltip
              border={true}
              borderColor="#000"
              place="bottom"
              effect="solid"
              textColor="#fff"
              backgroundColor="#333"
              className={styles.toolTipSmall}
              offset={{ top: -2 }}
              id={doc._id}
            />
          </li>
        ))}
      </ul>
      <a
        href="/integrations/"
        className="text-link font-medium hover:underline"
      >
        See all integrations <ArrowRight />
      </a>
    </>
  );
};
