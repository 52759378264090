import React, { useContext } from "react";
import { toPlainText } from "../../../lib/helpers";
import { addMinutes } from "date-fns";
import { format } from "date-fns-tz";
import { AddToCal, calDatetimeFormat } from "../../add-to-cal";
import { pageDocPath } from "../../../../helpers";
import { SiteContext } from "../../global/site-context";
import { RRule } from "rrule";

// Examples: "every week for 6 times", "every month on the 2nd Monday for 3 times"
// jakubroztocil.github.io/rrule
export const AddToCalBlock = ({
  startDate = new Date("2024-01-25 12:15"),
  duration = 60,
  recurring = "every month on the 1st Tuesday for 200 times",
}) => {
  const site = useContext(SiteContext);

  const date = new Date(startDate);
  const rucurRule = new RRule.fromText(recurring);
  const path = pageDocPath(site.doc);
  const url = `${process.env.HOST}${path}?utm_source=add-to-cal`;
  const fullContent = [toPlainText(site.doc._rawExcerpt)].join("\n");

  const calendarObj = {
    startDatetime: format(date, calDatetimeFormat),
    endDatetime: format(addMinutes(date, duration), calDatetimeFormat),
    // timezone: "America/Los_Angeles",
    duration: duration / 60,
    description: [fullContent, "", url].join("\n"),
    recurring: rucurRule.toString(),
    title: site.doc.title,
  };

  return <AddToCal event={calendarObj} />;
};
