import React from "react";
import { imageBuilder } from "../../lib/helpers";

const DEFAULT_QUALITY = 99;

export const ImageBuilder = ({
  image,
  alt,
  width,
  height,
  className,
  style,
  onClick,
  src,
  quality,
  draggable,
}) =>
  image?.asset?.metadata ? (
    <img
      src={imageBuilder(image)
        .withOptions(
          width && height
            ? {
                quality: quality || DEFAULT_QUALITY,
                auto: "format",
                width: width * 2,
                height: height * 2,
              }
            : width
            ? {
                quality: quality || DEFAULT_QUALITY,
                auto: "format",
                width:
                  image.asset.metadata?.dimensions?.width < width
                    ? Math.round(image.asset.metadata?.dimensions?.width / 2)
                    : width * 2,
              }
            : {
                quality: quality || DEFAULT_QUALITY,
                auto: "format",
                width: image?.asset?.metadata?.dimensions?.width,
              }
        )
        .url()}
      {...{
        width: width
          ? image.asset.metadata?.dimensions?.width < width &&
            image.asset.extension !== "svg"
            ? Math.round(image.asset.metadata?.dimensions?.width / 2)
            : width
          : height
          ? Math.round(height * image.asset.metadata.dimensions.aspectRatio)
          : Math.round(image.asset.metadata.dimensions.width / 2),
        height,
        alt,
        className,
        style,
        onClick,
        draggable,
      }}
    />
  ) : src ? (
    <img
      {...{ src, width, height, alt, className, style, onClick, draggable }}
    />
  ) : null;
