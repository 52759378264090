import React from "react";
import { Button } from "./button";
import AddToCalendarHOC, { SHARE_SITES } from "react-add-to-calendar-hoc";

export const calDatetimeFormat = "yyyyMMdd'T'HHmmss";

const CalendarButton = (args) => (
  <Button
    bgColor="bg-lime border border-limeDark"
    text={args.children}
    onClick={args.onClick}
    noLink={true}
  />
);

const CalendarDropdown = (args) => (
  <ul className={args.className}>
    {args.children.map((link, i) => (
      <li key={i}>{link}</li>
    ))}
  </ul>
);

const AddToCalendarDropdown = AddToCalendarHOC(
  CalendarButton,
  CalendarDropdown
);

export const AddToCal = ({ event }) => {
  const isClient = typeof navigator !== "undefined";
  const isiOS = isClient && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <div className="relative">
      <AddToCalendarDropdown
        event={event}
        dropdownProps={{
          className:
            "font-medium bg-light-80 rounded-b-md shadow-md absolute top-12 left-0 right-0 z-100 py-2",
        }}
        linkProps={{
          className: "text-link inline-block hover:text-black py-1",
        }}
        filename={`Common Room Event - ${
          event?.title ? event.title : "Invite"
        }`}
        items={
          isiOS
            ? [SHARE_SITES.GOOGLE, SHARE_SITES.ICAL, SHARE_SITES.YAHOO]
            : Object.values(SHARE_SITES)
        }
      />
    </div>
  );
};
