import React, { useContext } from "react";
import { cn, imageBuilder } from "../../lib/helpers";
import { Byline } from "../card/byline";
import { CardContext } from "../card/context";
import { LinkedIn } from "../icon/linkedin";
import { Slack } from "../icon/slack";
import { Twitter } from "../icon/twitter";
import PortableText from "../portableText";
import { SectionContext } from "./context";
import { SiteContext } from "../global/site-context";

// @todo Twitter mode

export const QuotePanels = ({ quotes, noBox, showLogo, showCardLogo }) => {
  const section = useContext(SectionContext);
  const site = useContext(SiteContext);

  return (
    <div
      className={cn(
        "grid sm:grid-cols-2 gap-4",
        quotes.length % 3 === 0 ? "md:grid-cols-3" : null,
        quotes.length > 3 ? "lg:grid-cols-4" : null
      )}
    >
      {quotes.map((q, i) => (
        <CardContext.Provider
          value={{
            authors: [
              {
                author: q?.authors.length > 0 ? q.authors[0].author : q?.author,
              },
            ],
            url: q.url,
          }}
          key={i}
        >
          {q.url && !isSlack(q.url) ? (
            <a
              className={cn(
                "border border-2 rounded-xl p-4",
                "transform transition-scale scale-100 hover:scale-105 hover:shadow-xl",
                q.url ? "pt-0" : "pt-6",
                i > 3 ? "hidden sm:block" : null,
                section.isDarkBg
                  ? "border-light-10"
                  : "bg-dark-5 border-dark-10"
              )}
              style={
                section.isDarkBg
                  ? { background: "rgba(56, 168, 243, 0.16)" }
                  : null
              }
              href={q.url}
              target="_blank"
              key={`${i}-${q.url}`}
            >
              <QuoteCardContent {...{ q, quotes, showLogo }} />
            </a>
          ) : (
            <div
              key={i}
              className={cn(
                q.url ? "pt-0" : "pt-6",
                !noBox ? "border rounded-xl p-5" : null,
                showLogo ? "pb-2" : null,
                !noBox
                  ? section.isDarkBg
                    ? "bg-light-10 border-light-10"
                    : site?.doc.backgroundColor === "lightLavender"
                    ? "bg-white border-dark-10"
                    : "bg-dark-2 border-dark-10"
                  : null,

                i > 3 ? "hidden sm:block" : null
              )}
            >
              <QuoteCardContent {...{ q, quotes, showLogo, showCardLogo }} />
            </div>
          )}
        </CardContext.Provider>
      ))}
    </div>
  );
};

export const isLinkedIn = (url) =>
  url && url.match(/^https:\/\/www\.linkedin\.com/);

export const isSlack = (url) => url && url.match(/\.slack\.com\//);

export const QuoteCardContent = ({
  q,
  quotes,
  showLogo,
  showCardLogo,
  skipHeadline = true,
  boldText,
}) => {
  const section = useContext(SectionContext);

  return (
    <div
      className={cn(
        "relative h-full",
        showLogo ? "pb-36" : q.url ? null : "pb-16"
      )}
    >
      {q.url && (
        <div className="flex justify-between my-4">
          <Byline hideBorder={true} />
          {isLinkedIn(q.url) ? (
            <span className={!section.isDarkBg ? "text-brand-linkedin" : null}>
              <LinkedIn />
            </span>
          ) : isSlack(q.url) ? (
            <span className={!section.isDarkBg ? "text-brand-slack" : null}>
              <Slack size={20} />
            </span>
          ) : (
            <span className={!section.isDarkBg ? "text-brand-twitter" : null}>
              <Twitter />
            </span>
          )}
        </div>
      )}

      <div
        className={
          q.color ? "transform transition-size scale-100 hover:scale-105" : null
        }
      >
        <div
          className={q.color ? `rounded-2xl p-6 relative bg-${q.color}` : null}
        >
          {showCardLogo &&
            (q.companies?.[0]?.mainImageMono ||
              q.companies?.[0]?.mainImage) && (
              <img
                src={imageBuilder(
                  section.isDarkBg && q.companies?.[0]?.mainImageMono
                    ? q.companies[0].mainImageMono
                    : q.companies[0].mainImage
                )
                  .height(200)
                  .quality(100)
                  .auto("format")
                  .url()}
                style={{ maxHeight: "70px" }}
                className="mb-4 mt-2"
                alt={q.companies[0].title}
              />
            )}

          {!skipHeadline && q.headline && (
            <h4 className="font-semibold text-lg md:text-xl mb-4">
              {q.headline}
            </h4>
          )}
          {q._rawExcerpt || q.excerpt ? (
            <PortableText
              blocks={q._rawExcerpt || q.excerpt}
              className={cn(
                !section.isDarkBg ? "text-secondary" : null,
                boldText ? "font-medium" : null
              )}
            />
          ) : (
            q.text
          )}
        </div>
        {q.color && (
          <div className="ml-8">
            <Arrow direction="up" color={q.color} className="z-10" />
          </div>
        )}
      </div>
      {!q.url && (
        <div className="flex justify-start absolute bottom-0 left-0">
          {q.authors ? (
            <div className={cn("mt-4", q.color ? "px-7" : null)}>
              <Byline
                skipCompany={true}
                hideBorder={true}
                showLogo={showLogo}
              />
            </div>
          ) : q.author ? (
            <div className="mt-4 px-7">
              <Byline
                skipCompany={true}
                hideBorder={true}
                showLogo={showLogo}
              />
            </div>
          ) : (
            <div
              className={cn("bg-no-repeat bg-contain", q.className)}
              style={{
                backgroundImage: `url('/static/img/${q.image}')`,
                backgroundPosition: "center",
              }}
            ></div>
          )}
        </div>
      )}
    </div>
  );
};

export const Arrow = ({ direction, color, className }) => {
  let style = {};
  const invisible = "20px solid transparent";

  if (direction === "up") {
    style = {
      borderLeft: invisible,
      borderRight: invisible,
      borderTopWidth: "20px",
    };
  } else if (direction === "right") {
    style = {
      borderLeftWidth: "20px",
      borderTop: invisible,
      borderBottom: invisible,
    };
  }

  return (
    <div
      className={cn(color, className, `border-t-8 border-${color}`, "w-0 h-0")}
      style={style}
    ></div>
  );
};
