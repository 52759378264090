import React from "react";
import { toPlainText } from "../../../lib/helpers";
import { ImageBuilder } from "../../global/image-builder";
import { APPLE_SHOW_URL, SPOTIFY_SHOW_URL } from "./podcast";

export const ResourcesHeader = ({ title, tag, suffix }) => {
  const isPodcast = tag?.slug.current === "podcast";

  return (
    <div className="mt-20 text-center">
      {/* <EyebrowPill>Resources</EyebrowPill> */}
      <h1 className="text-3xl lg:text-4xl mb-2 font-bold tracking-tight">
        {title || "Explore what's possible with Common Room"}
        {suffix}
      </h1>
      {tag && (
        <div className="max-w-screen-sm mx-auto">
          {tag.headline && (
            <h2 className="text-xl mb-4 text-secondary">{tag.headline}</h2>
          )}
          <h3 className="text-lg font-medium tracking-tight">
            {toPlainText(tag._rawExcerpt)}
          </h3>
        </div>
      )}
      {isPodcast && (
        <div className="flex gap-12 justify-center items-center pt-4">
          <a
            href={APPLE_SHOW_URL}
            target="_blank"
            class="bg-white rounded-lg p-2 inline-block h-full transform hover:scale-105 transition-transform"
          >
            <ImageBuilder
              src="/static/img/apple-podcasts-mono-black.svg"
              className="w-40"
            />
          </a>
          <a
            href={SPOTIFY_SHOW_URL}
            target="_blank"
            className="transform hover:scale-105 transition-transform"
          >
            <ImageBuilder
              src="/static/img/spotify-podcast-wht-blk.svg"
              className="w-48"
            />
          </a>
        </div>
      )}
    </div>
  );
};
