import React, { useContext } from "react";
import { cn, mapEdgesToNodes } from "../../../lib/helpers";
import { graphql, useStaticQuery } from "gatsby";
import ReactTooltip from "react-tooltip";
import {
  faArrowsSplitUpAndLeft,
  faWrench,
  faBullhorn,
  faMouse,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import { faComments } from "@fortawesome/free-regular-svg-icons";
import { SiteContext } from "../../global/site-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as styles from "../snowflake/pricing-plans.module.css";
import { ImageBuilder } from "../../global/image-builder";
import { pageDocPath } from "../../../../helpers";

const LOGO_IMAGE_SIZE = 48;

// @todo group by source group not sourceType
const SOURCE_TYPE_MAP = [
  {
    name: "Product usage",
    title: "Product-led? No problem",
    description:
      "Pull in product data with built-in integrations for your data warehouse, CDP, or reverse ETL platform.",
    value: "product",
    combinesWith: "custom",
    icon: faMouse,
  },
  {
    name: "Go-to-market",
    title: "GTM source of truth",
    description:
      "Connect the dots to close with native integrations for CRM, marketing automation, and sales engagement platforms.",
    value: "gtm",
    icon: faBullhorn,
  },
  {
    name: "Community",
    title: "Unlock community growth",
    description:
      "Activate your community with native integrations and automations for Slack and Discord.",
    value: "community",
    icon: faUserFriends,
  },
  {
    name: "Social",
    title: "Shine light on the dark funnel",
    description:
      "Detect early intent signals happening on user forums, communities, and social channels.",
    value: "social",
    combinesWith: "community",
    icon: faComments,
  },
  {
    name: "Code collaboration",
    title: "Git after it",
    description:
      "Connect to GitHub and other code collab sources to identify the person behind the pull request.",
    value: "code",
    icon: faArrowsSplitUpAndLeft,
  },
  {
    name: "Custom",
    title: "Data your way",
    description:
      "Need to connect to something we haven't thought of? Use our API, webhooks, Zapier, or CSV import.",
    value: "custom",
    icon: faWrench,
  },
];

const SHORT_LIST = ["product", "gtm", "social"];

// @todo change filter back to isPublished

export const query = graphql`
  query IntegrationsOverviewQuery {
    docs: allSanityIntegration(
      sort: { fields: [orderRank] }
      filter: {
        status: { in: ["published", "hidden"] }
        mainImageSolid: { asset: { _id: { ne: null } } }
      }
    ) {
      edges {
        node {
          _type
          _id
          company
          mainImageSolid {
            ...SanityImageSimple
          }
          status
          sourceType
          signalSource {
            _id
            title
            slug {
              current
            }
          }
          _rawBody(resolveReferences: { maxDepth: 12 })
          docRef {
            _id
            _type
            slug {
              current
            }
            menu
            menuParent
          }
        }
      }
    }
  }
`;

// @todo group by source group not sourceType

export const IntegrationsOverview = ({ section }) => {
  const site = useContext(SiteContext);
  const { docs } = useStaticQuery(query) || {};
  const docList = docs ? mapEdgesToNodes(docs) : [];

  const isCombined = site?.doc?.contentType === "landing";
  const isIcons = site?.doc?.contentType === "solution";

  const sourceMap = !isCombined
    ? SOURCE_TYPE_MAP
    : SOURCE_TYPE_MAP.filter((t) => SHORT_LIST.includes(t.value));

  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-3">
      {sourceMap.map((sourceType) => (
        <div
          className="p-6 border border-dark-10 rounded-lg bg-white"
          key={sourceType.value}
        >
          <ul className="flex flex-wrap mb-4">
            {docList
              .filter(
                (d) =>
                  d.sourceType === sourceType.value ||
                  (isCombined && d.sourceType === sourceType.combinesWith)
              )
              .slice(0, 4)
              .map((doc) => (
                <li key={doc._id}>
                  <a
                    className="flex items-center"
                    href={
                      site.isActive("linkIntegrations") && doc?.docRef
                        ? pageDocPath(doc.docRef)
                        : null
                    }
                    data-tip={doc.company}
                    data-for={doc._id}
                  >
                    <ImageBuilder
                      className="block mr-2 mb-2 rounded-md overflow-hidden w-12"
                      image={doc.mainImageSolid}
                      height={LOGO_IMAGE_SIZE}
                      width={LOGO_IMAGE_SIZE}
                      quality={100}
                      alt={doc.company}
                    />
                  </a>
                  <ReactTooltip
                    border={true}
                    borderColor="#000"
                    place="bottom"
                    effect="solid"
                    textColor="#fff"
                    backgroundColor="#333"
                    className={styles.toolTipSmall}
                    offset={{ top: -2 }}
                    id={doc._id}
                  />
                </li>
              ))}
          </ul>
          {isIcons ? (
            <>
              <FontAwesomeIcon icon={sourceType.icon} className="mr-2" />
              {sourceType.name}
            </>
          ) : (
            <>
              <h4 className="font-semibold text-xl">{sourceType.title}</h4>
              <p className="mt-3 text-secondary">{sourceType.description}</p>
            </>
          )}
        </div>
      ))}
    </div>
  );
};
