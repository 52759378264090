import React, { useContext, useEffect, useState } from "react";
import { CloseIcon } from "./icon/close-x";
import { ArrowLeft } from "./icon/arrow-left";
import { cn, dualTypeTrue } from "../lib/helpers";
import { Button } from "./button";
import { SiteContext } from "./global/site-context";
import { Modal } from "./global/modal";
import { CustomerLogos, HubspotWebform } from "./sections";
import { pageDocPath, trackPath } from "../../helpers";
import { PAGE_VIEWS_STASH, useScore } from "../lib/score";
import { FIRST_SEEN_STASH } from "../lib/client-attribution";
import { differenceInCalendarDays } from "date-fns";
import { InlineWidget } from "react-calendly";
import { ImageBuilder } from "./global/image-builder";

const POPUP_DISMISS_STASH = "popup.dismissed";
export const HAS_SENT_CONTACT_STASH = "action.contactSend";
export const DELAY_FORM_SUBMIT = 2200;

const SCORE_THRESHOLD = 8;
const PAGEVIEW_THRESHOLD = 5;
const FIRST_SEEN_THRESHOLD = 7;

// @todo skip signup on all hello pages

const POPUP_EXCLUDE_PATHS = [
  "/contact/",
  "/demo/",
  "/pricing/",
  "/join-slack/",
  "/hello/",
  "/acquired/",
  "/meeting/1to1/",
  "/meeting/30mins/",
  "/unsubscribe-onboarding/",
  "/help/",
  "/devrel-github-list/",
  "/playbooks/",
  "/resources/",
  "/resources-new/",
  "/resources/signals/",
  "/community/",
  "/uncommon/",
  "/series/mavericks/",
  "/prompts/",
];

export const includePromos = (doc) => {
  const path = pageDocPath(doc);

  // note reverse logic to exclude
  return ["event", "documentation", "company"].includes(doc._type) ||
    doc?.contentType === "landing" ||
    path.match(/^\/hello\//) ||
    POPUP_EXCLUDE_PATHS.includes(path)
    ? false
    : true;
};

// popup header includes HTML break
const POPUP_BUTTON = "Request live demo";
const FORM_BUTTON = "Request demo";
const FORM_HEADER = "See us in action";

const FORM_MODE = false;

export const StickyPopup = () => {
  const site = useContext(SiteContext);
  // use page intent value to calculate a running total.
  const score = useScore({
    storage: site.metrics.analytics.storage,
    doc: site?.doc,
  });

  const localStorage = site.metrics.analytics.storage;
  const storageBackend =
    typeof window !== "undefined"
      ? window.sessionStorage || localStorage
      : null;

  const wasDismissed = dualTypeTrue(localStorage.getItem(POPUP_DISMISS_STASH));
  const isExcluded =
    localStorage && dualTypeTrue(localStorage.getItem(HAS_SENT_CONTACT_STASH));

  const pageViews =
    storageBackend && parseInt(storageBackend.getItem(PAGE_VIEWS_STASH));
  const firstSeen = site.metrics.analytics.storage.getItem(FIRST_SEEN_STASH);
  const daysSinceFirstSeen = differenceInCalendarDays(
    new Date(),
    new Date(firstSeen)
  );

  const showPopup =
    (score >= SCORE_THRESHOLD ||
      pageViews >= PAGEVIEW_THRESHOLD ||
      daysSinceFirstSeen >= FIRST_SEEN_THRESHOLD) &&
    includePromos(site);

  const [isExpanded, setExpanded] = useState(!wasDismissed);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (isExcluded || wasDismissed || !showPopup) return;

    site.metrics.analytics.once("ready", () => {
      site.metrics.logEvent({
        category: "passive",
        action: "popup-view",
        label: "book-demo",
      });
    });
  }, []);

  return isExcluded || !showPopup ? null : isOpen ? (
    <Modal setOpen={setOpen} maxWidth="800">
      <div className="lg:grid lg:grid-cols-2 lg:gap-8">
        <div className="hidden lg:block">
          <h3 className="text-xl-homepage font-semibold mb-8 mt-6">
            {FORM_HEADER}
          </h3>
          <p>
            See how Common Room can help you grow happier customers, measure
            outcomes, and drive business impact.
          </p>
          <p className="font-semibold mt-10 mb-4">What's in a demo?</p>
          <ul className="list-disc pl-4">
            <li>We'll show you live how Common Room can help</li>
            <li>Explore the use cases that interest you</li>
            <li>Share how your peers are using Common Room</li>
            <li>Answer your questions</li>
          </ul>
          <div className="mt-10">
            <h4 className="font-semibold mb-4">
              Today's fastest growing companies choose{" "}
              <span className="whitespace-nowrap">Common Room:</span>
            </h4>
            <CustomerLogos section={{ container: "thin" }} />
          </div>
        </div>
        <div>
          {FORM_MODE ? (
            <HubspotWebform
              section={{
                buttonText: FORM_BUTTON,
              }}
              onFormSubmitted={() => {
                localStorage.setItem(HAS_SENT_CONTACT_STASH, true);
                setTimeout(() => {
                  setOpen(false);
                  setExpanded(false);
                }, DELAY_FORM_SUBMIT);
              }}
              attribution={{
                action: "popup-submit",
                label: "book-demo",
                website: `popup--${trackPath(site?.doc)}`,
              }}
              // @todo pass score into field
            />
          ) : (
            <InlineWidget
              url={`${site.calendly}?hide_gdpr_banner=1`}
              utm={{
                utmMedium: "popup",
                utmSource: "book-demo",
                utmTerm: score,
                utmContent: trackPath(site?.doc),
              }}
              pageSettings={{
                hideEventTypeDetails: true,
                hideLandingPageDetails: true,
                primaryColor: "8062B0",
                //   textColor: "4d5055",
              }}
              // styles={{ height: "900px" }}
            />
          )}
        </div>
      </div>
    </Modal>
  ) : (
    <div
      className={cn(
        "z-90 overflow-hidden bg-primary text-white fixed bottom-24 p-2 shadow-lg cursor-pointer",
        isExpanded
          ? "sm:w-96 left-8 sm:left-auto right-8 rounded-xl outline outline-lightBorder"
          : "right-0 p-4 rounded-tl-xl rounded-bl-xl"
      )}
      onClick={(e) => {
        if (isExpanded) {
          setOpen(true);
          site.metrics.logClick("popup-open", "book-demo");
        } else {
          setExpanded(true);
          localStorage.setItem(POPUP_DISMISS_STASH, false);
        }
      }}
    >
      <ImageBuilder
        className="opacity-10 absolute -top-8 right-0 bottom-0 left-0"
        src="/static/img/emoji-background-blank.png"
      />
      {isExpanded ? (
        <div className="relative pl-4 lr-4">
          <span
            className="float-right p-3 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setExpanded(false);
              localStorage.setItem(POPUP_DISMISS_STASH, true);
              site.metrics.logClick("popup-dismiss", "book-demo");
            }}
          >
            <CloseIcon size="18" />
          </span>
          <div className="py-4">
            <p className="font-bold tracking-tight text-xl mb-6">
              Ready to see Common Room <br className="hidden sm:block" /> in
              action?
            </p>
            <div className="flex justify-center">
              <Button text={POPUP_BUTTON} bgColor="bg-white" noLink={true} />
            </div>
          </div>
        </div>
      ) : (
        <ArrowLeft />
      )}
    </div>
  );
};
